<template>
  <div class="d-flex">
    <increase-limits-dialog v-if="isShowAddonsModal"/>
    <cancel-subscription :addonConsumedLimitDetail="addonConsumedLimitDetail"/>

    <div class="d-flex justify_center main_loader">
      <clip-loader v-if="!subscription_detail" :color="'#0095f3'"></clip-loader>
    </div>

    <div v-if="subscription_detail" class="width_65">
      <div class="d-flex">
        <div :class="{'current_sb_width':!subscription_detail.payment_details.subscription}"
             class="d-inline-flex current_subscription">

          <img class="current_subscription_image" src="/assets/img/billing/Mask Group 49.png">

          <div class="content_section">
            <p><strong>Current Plan</strong></p>
            <p class="mt-3" v-if="subscription_detail && subscription_detail.payment_details && subscription_detail.payment_details.subscription && !isBruceCampaign()">
              <strong style="font-size: 20px">
                {{ findSymbolFromCurrencyCode(subscription_detail.payment_details.subscription.currency) }}
                {{ isBruceCampaign() ? getProfile.subscription.price : subscription_detail.payment_details.subscription.last_payment_amount }}
              </strong>
              <span>/ {{ subscriptionDuration }}</span>
            </p>

            <p class="mt-3 plan_name text-capitalize" v-if="subscription_detail && subscription_detail.subscription_name">
              <strong v-tooltip="subscription_detail.subscription_name">{{ limitTextLength(subscription_detail.subscription_name, 40) }}</strong></p>
            <div class="subscription_btns">
              <ui-button
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="`${!!getProfile.cancellation_effective_at ? 'Renew Plan' : (isBruceCampaign() ? 'Upgrade Lifetime Plan' : 'Upgrade Plan')}`"
                :fontSize="'12px'"
                v-if="getSubscription && getSubscription.fastspring_name"
                @click="showUpgradePlanModal"
              />

              <template v-if="!isBruceCampaign()">
                <ui-button
                  :color="'#3988fe'"
                  :bgColor="'rgba(69, 162, 255, 0.1)'"
                  :text="'Pause Subscription'"
                  :fontSize="'12px'"
                  :showLoader="getBillingLoaders.pause_subscription"
                  :disabled="getBillingLoaders.pause_subscription"
                  class="ml-1"
                  v-if="subscription_detail.payment_details.subscription && !subscription_detail.payment_details.subscription.paused_from && !isLifeTimeUser && !getProfile.cancellation_effective_at"
                  @click="pauseSubscription"
                />
                <ui-button
                  :color="'#3988fe'"
                  :bgColor="'rgba(69, 162, 255, 0.1)'"
                  :text="'Resume Subscription'"
                  :fontSize="'12px'"
                  :showLoader="getBillingLoaders.resume_subscription"
                  :disabled="getBillingLoaders.resume_subscription"
                  class="ml-1"
                  v-if="subscription_detail.payment_details.subscription && subscription_detail.payment_details.subscription.paused_from && !getProfile.cancellation_effective_at"
                  @click="resumeSubscription"
                />
                <ui-button
                  class="ml-1"
                  :color="'#ff4c4c'"
                  :bgColor="'rgba(69, 162, 255, 0.1)'"
                  :text="'Cancel Subscription'"
                  :fontSize="'12px'"
                  @click="showCancelSubscriptionModal('main_subscription')"
                  v-if="!isShowNextPayment && !isLifeTimeUser && !getProfile.cancellation_effective_at"
                />
              </template>
            </div>
          </div>
        </div>

        <div class="next_payment"
             v-if="isShowNextPayment && !getProfile.cancellation_effective_at">
          <img class="bg" src="/assets/img/billing/next_payment_bg.png">
          <div class="content">
            <div class="d-flex justify-content-between heading">
              <strong>Next Payment</strong>
              <img src="/assets/img/billing/next_payment_icon.png">
            </div>
            <div class="payment">
              <p class="price">
                <strong>
                  {{
                    findSymbolFromCurrencyCode(subscription_detail.payment_details.subscription.currency)
                  }}
                  {{ subscription_detail.payment_details.subscription.next_payment_amount }}
                </strong>
              </p>
            </div>
            <div class="mt-2" v-if="subscription_detail.payment_details">
              <strong>on
                {{ fetchBillingDate(subscription_detail.payment_details.subscription.next_bill_date) }}
              </strong>
            </div>

            <ui-button
              class="cancle_subscription_btn"
              :color="'#ff4c4c'"
              :bgColor="'rgba(69, 162, 255, 0.1)'"
              :text="'Cancel Subscription'"
              :fontSize="'12px'"
              v-if="!isBruceCampaign()"
              @click="showCancelSubscriptionModal('main_subscription')"
            />
          </div>
        </div>
      </div>

      <!--      add on subscription -->
      <div v-if="!trialUser" class="d-flex subscription_details">

        <div v-if="addon_subscriptions_items && addon_subscriptions_items.length" class="content_section">
          <div class="content_header">
            <p>Add-on-Subscriptions</p>
          </div>
          <div class="add_on_subscription_table"> <!--v-if="!loaders.addons"-->
            <b-table @row-clicked="expandAdditionalInfo" class="mt-2 ads_on_subscription"
                     :items="addon_subscriptions_items" :fields="addon_subscriptions_fields" responsive="sm">
              <template #cell(ACTION)="row">
                <span class="text-center">
                  <i type="button" v-if="row.item.STATUS !== 'Deleted'" class="fas fa-arrow-up mr-3 cursor_pointer"
                     @click.stop="updateAddonDetails(row)"></i>
                  <i class="fa fa-trash-alt" v-if="row.item.STATUS !== 'Deleted'"
                     @click.stop="showCancelSubscriptionModal('addon_subscription', row)"></i>
                </span>
              </template>
              <template slot="row-details" slot-scope="row">
                <transition name="fade">
                  <b-card class="expand_card">
                    <div :key="index" v-for="(item, index) in row.item.modifiers" class="">
                      <div class="d-flex border-bottom justify-content-between">
                        <p class="modifier_heading font-weight-bold">Add-on {{ index + 1 }} ({{
                            findSymbolFromCurrencyCode(addon_subscriptions_items[0].currency)
                          }}{{ item.modifier_amount }} / {{ item.bill_type }})</p>
                        <div>
                          <i type="button" v-if="!!item.modifier_id && row.item.STATUS !== 'Deleted'"
                             @click="showUpgradeLimitsModal(item)"
                             class="fas ml-2 fa-pencil"></i>
                          <i type="button" v-if="!!item.modifier_id && row.item.STATUS !== 'Deleted'"
                             @click="showCancelSubscriptionModal('addon_modifier' ,item)"
                             class="fas ml-2 fa-trash-alt"></i>
                        </div>
                      </div>
                      <div class="d-flex mt-3 flex-row flex-wrap justify-content-between">
                        <div class="flex_item">
                          <strong>{{ item.addons.clicks }}</strong>
                          <p>Clicks</p>
                        </div>
                        <div class="flex_item">
                          <strong>{{ item.addons.brands }}</strong>
                          <p>Brands</p>
                        </div>
                        <div class="flex_item">
                          <strong>{{ item.addons.campaigns }}</strong>
                          <p>Campaigns</p>
                        </div>
                        <div class="flex_item">
                          <strong>{{ item.addons.team_members }}</strong>
                          <p>Team Members</p>
                        </div>
                        <div class="flex_item">
                          <strong>{{ item.addons.bio_links }}</strong>
                          <p>Bio Links</p>
                        </div>
                        <div class="flex_item">
                          <strong>{{ item.addons.retargeting_pixels }}</strong>
                          <p>Retargeting Pixels</p>
                        </div>
                        <div class="flex_item">
                          <strong>{{ item.addons.custom_domains }}</strong>
                          <p>Custom Domains</p>
                        </div>
                        <div class="flex_item">
                          <strong v-if="item.addons.branding">Yes</strong>
                          <strong v-if="!item.addons.branding">No</strong>
                          <p>Branding</p>
                        </div>
                      </div>
                    </div>
                    <i type="button" style="float:right;color:#45a2ff" @click="row.toggleDetails"
                       class="fas mt-2 fa-chevron-up"></i>
                  </b-card>
                </transition>
              </template>
            </b-table>
            <div class="no_record" v-if="!getBillingLoaders.addons && addon_subscriptions_items.length===0">
              <p>No record found</p>
            </div>
            <clip-loader v-if="getBillingLoaders.addons" :size="'14px'" :color="'#0095f3'"></clip-loader>
          </div>
        </div>

        <!--         second subscription here-->
        <div style="padding-bottom: 20px" class="content_section">
          <div class="content_header">
            <p>Payment History</p>
          </div>
          <table class="table mt-2 table-borderless">
            <thead>
            <tr>
              <th scope="col" class="text-center">ORDER ID</th>
              <th scope="col" class="text-center">AMOUNT</th>
              <th scope="col" class="text-center">DATE</th>
              <th scope="col" class="text-center">STATUS</th>
              <th scope="col" class="text-center">ACTION</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in paymentHistoryPagination.items">
              <td class="text-center">{{ item.order_id }}</td>
              <td class="text-center">
                <strong>
                  {{
                    findSymbolFromCurrencyCode(item.currency)
                  }}
                  {{ item.amount }}</strong>
              </td>
              <td class="text-center">{{ fetchBillingDate(item.paid_at) }}</td>
              <td class="text-center capitalize__text">{{ item.status }}</td>
              <td class="text-center">
                <a class="text-center" target="_blank" :href="item.receipt_url">
                  <i class="fa fa-download"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="no_record" v-if="!getBillingLoaders.invoices && paymentHistoryPagination.items.length===0">
            <p>No record found</p>
          </div>
          <clip-loader v-if="getBillingLoaders.invoices" :size="'14px'" :color="'#0095f3'"></clip-loader>
        </div>

        <template v-if="paymentHistoryPagination.total_items > paymentHistoryPagination.limit">
          <pagination
            :clickedMethod="paginatePastTransactions"
            :resultCount="paymentHistoryPagination.total_items"
            :itemsPerPage="paymentHistoryPagination.limit"
            :currentPage="paymentHistoryPagination.page"
            :totalPagesNumber="paymentHistoryPagination.total_pages"
          ></pagination>
        </template>

      </div>
    </div>

    <!--    limits and card info-->
    <div class="w-100" v-if="subscription_detail">
      <div class="payment_info"
           v-if="subscription_detail && subscription_detail.payment_details && subscription_detail.payment_details.subscription">
        <div class="d-flex justify-content-between">
          <p class="d-inline-flex"><strong>Payment Information</strong></p>
          <span class="d-inline-flex edit_payment_info"
                @click="updatePayment(subscription_detail.payment_details.subscription.update_url)">
            <img src="/assets/img/billing/edit.png">
          </span>
        </div>

        <div class="details d-flex mt-1">
          <img src="/assets/img/billing/Group 44033.png">
          <div class="col">
            <p class="mt-4">Last 4 digits: &nbsp;
              <strong> {{ computePaymentInformation('last_four_digits') }} </strong>
            </p>
            <p class="mt-2">Expiry date: &nbsp;
              <strong> {{ computePaymentInformation('expiry_date') }} </strong>
            </p>
          </div>
        </div>
      </div>


      <div class="limits">
        <p><strong>Limits</strong></p>
        <div class="subscription-plan-widget mt-4">

          <!-- Clicks-->
          <div style="padding: 10px">
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Clicks</p>
                <p style="float: right" class="opacity-60" v-if="subscription_detail.total_limits.clicks < 10000000">
                  <strong>{{
                      numberFormatter(computeClickLimits(subscription_detail.used_limits.clicks, subscription_detail.total_limits.clicks))
                    }} of
                    {{ subscription_detail.total_limits.clicks }}</strong></p>
                <p style="float: right" class="opacity-60" v-else><strong>{{ subscription_detail.used_limits.clicks }}
                  of
                  Unlimited</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.clicks, subscription_detail.total_limits.clicks)"
                  :value="subscription_detail.used_limits.clicks"
                  :max="subscription_detail.total_limits.clicks" class="mb-1"></b-progress>
              </div>
            </div>

            <!-- Brands-->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Brands</p>
                <p class="opacity-60" v-if="subscription_detail.total_limits.brands < 1000">
                  <strong>{{ subscription_detail.used_limits.brands }} of
                    {{ subscription_detail.total_limits.brands }}</strong></p>
                <p class="opacity-60" v-else><strong>{{ subscription_detail.used_limits.brands }} of
                  Unlimited</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.brands, subscription_detail.total_limits.brands)"
                  :value="subscription_detail.used_limits.brands"
                  :max="subscription_detail.total_limits.brands" class="mb-1"></b-progress>
              </div>
            </div>

            <!--  Campaigns -->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Campaigns</p>
                <p class="opacity-60" v-if="subscription_detail.total_limits.campaigns < 1000">
                  <strong>{{ subscription_detail.used_limits.campaigns }} of
                    {{ subscription_detail.total_limits.campaigns }}</strong></p>
                <p class="opacity-60" v-else><strong>{{ subscription_detail.used_limits.campaigns }} of
                  Unlimited</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.campaigns, subscription_detail.total_limits.campaigns)"
                  :value="subscription_detail.used_limits.campaigns"
                  :max="subscription_detail.total_limits.campaigns" class="mb-1"></b-progress>
              </div>
            </div>

            <!--          team members-->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Team Members</p>
                <p class="opacity-60"><strong>{{ subscription_detail.used_limits.team }} of
                  {{ subscription_detail.total_limits.team_members }}</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.team, subscription_detail.total_limits.team_members)"
                  :value="subscription_detail.used_limits.team"
                  :max="subscription_detail.total_limits.team_members" class="mb-1"></b-progress>
              </div>
            </div>

            <!--  Bio Links-->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Bio Links</p>
                <p class="opacity-60" v-if="subscription_detail.total_limits.bio_links < 1000">
                  <strong>{{ subscription_detail.used_limits.bio }} of
                    {{ subscription_detail.total_limits.bio_links }}</strong></p>
                <p class="opacity-60" v-else><strong>{{ subscription_detail.used_limits.bio }} of
                  Unlimited</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.bio, subscription_detail.total_limits.bio_links)"
                  :value="subscription_detail.used_limits.bio"
                  :max="subscription_detail.total_limits.bio_links" class="mb-1"></b-progress>
              </div>
            </div>

            <!--  Pixels-->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Retargeting Pixels</p>
                <p class="opacity-60" v-if="subscription_detail.total_limits.retargeting_pixels < 1000">
                  <strong>{{ subscription_detail.used_limits.pixels }} of
                    {{ subscription_detail.total_limits.retargeting_pixels }}</strong></p>
                <p class="opacity-60" v-else><strong>{{ subscription_detail.used_limits.pixels }} of
                  Unlimited</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.pixels, subscription_detail.total_limits.retargeting_pixels)"
                  :value="subscription_detail.used_limits.pixels"
                  :max="subscription_detail.total_limits.retargeting_pixels" class="mb-1"></b-progress>
              </div>
            </div>

            <!--  Custom Domains-->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Custom Domains</p>
                <p class="opacity-60" v-if="subscription_detail.total_limits.custom_domains < 100">
                  <strong>{{ subscription_detail.used_limits.domains }} of
                    {{ subscription_detail.total_limits.custom_domains }}</strong></p>
                <p class="opacity-60" v-else><strong>{{ subscription_detail.used_limits.domains }} of
                  Unlimited</strong></p>
              </div>
            </div>
            <div class="subscription-widget-stats mb-3">
              <div class="progress_bar_limits" style="height: 7px;">
                <b-progress
                  :variant="computeLimitsProgressColor(subscription_detail.used_limits.domains, subscription_detail.total_limits.custom_domains)"
                  :value="subscription_detail.used_limits.domains"
                  :max="subscription_detail.total_limits.custom_domains" class="mb-1"></b-progress>
              </div>
            </div>

            <!--  Branding -->
            <div class="subscription-widget-information">
              <div class="container row">
                <p class="flex-grow-1 opacity-70">Branding</p>
                <p class="opacity-60">
                  <strong v-if="subscription_detail.total_limits.branding">Yes</strong>
                  <strong v-else>No</strong>
                </p>
              </div>
            </div>


            <div class="upgrade_btn mt-3"
                 v-if="!trialUser && isAgency() && !getProfile.cancellation_effective_at && !isAddonSubscriptionPaused">
              <ui-button
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'Upgrade Limits'"
                :fontSize="'12px'"
                @click="showUpgradeLimitsModal()"
              />
            </div>
          </div>
        </div>
      </div>


      <!--      <div>-->
      <!--        -->
      <!--      </div>-->
    </div>

  </div>
</template>

<style lang="less">
.current_subscription {
  //padding: 15px;
  width: 100%;
  background: rgba(58, 69, 87, 0.04);
  position: relative;
  //min-width: 370px;
  max-width: 500px;
  border-radius: 10px;
  margin: 15px 0px;
  height: 182px;

  .current_subscription_image {
    position: absolute;
    right: 0;
  }

  .content_section {
    padding: 15px;
    color: #1f216e !important;

    .subscription_btns {
      margin-top: 10px;
      bottom: 10px;
      position: absolute;
    }

    .plan_name {
      position: absolute;
      bottom: 60px;
    }

    p {
      color: #1f216e;

      .fa-dollar-sign {
        font-size: 18px;
      }
    }
  }
}

.next_payment {
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
  width: 100%;
  background-image: url('/assets/img/billing/Group 44812.png');
  position: relative;
  min-width: 370px;
  border-radius: 10px;
  margin: 15px 10px;
  margin-right: 0 !important;

  .content {
    position: relative;

    .d-flex {
      strong {
        color: #1f216e
      }
    }

    .payment {
      .price {
        margin-top: -7px;

        strong {
          color: #1f216e;
          font-size: 20px;
        }
      }
    }

    .mt-2 {
      color: #1f216e;
      margin-top: 16px !important;
    }

    .cancle_subscription_btn {
      margin-top: 14px;
    }
  }

  .bg {
    position: absolute;
    bottom: 0;
  }

  .heading {
    img {
      width: 43px;
    }
  }

  .payment {
    i {
      font-size: 18px;
    }
  }
}

.payment_info {
  padding: 15px;
  width: 100%;
  background: rgba(58, 69, 87, 0.04);
  position: relative;
  min-width: 370px;
  border-radius: 10px;
  margin: 15px 10px;

  .d-flex {
    .d-inline-flex {
      strong {
        color: #1f216e
      }
    }
  }

  .edit_payment_info {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: 1px 1px 4px #2d99ec;
      transition: all 0.2s ease-in-out;
    }

    img {
      width: 16px;
      height: 16px;
      margin-top: 9px;
    }
  }

  .details {
    .col {
      .mt-4 {
        color: #1f216e;
      }

      .mt-2 {
        color: #1f216e;
      }
    }
  }
}

.limits {
  padding: 15px;
  background: #f7fbfc;
  position: relative;
  border-radius: 10px;
  margin: 15px 0px 15px 10px;

  p {
    strong {
      color: #1f216e;
    }
  }

  .subscription-plan-widget {
    color: #1f216e;

    .subscription-widget-information {
      .container {
        .flex-grow-1 {
          color: #1f216e;

          strong {
            color: #1f216e;
          }
        }

        .opacity-70 {
          color: #1f216e !important;

          strong {
            color: #1f216e !important;
          }
        }
      }
    }

    .upgrade_btn {
      .add-btn {
        width: 100% !important;
      }
    }
  }
}

.subscription_details {
  flex-direction: column;

  .content_section {
    border-radius: 10px;
    background: #f7fbfc;

    &:nth-child(2) {
      margin-top: 15px;
    }

    .add_on_subscription_table {
      padding-bottom: 20px;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;

      p {
        strong {
          color: #2d3f8e;
        }
      }

      .no_record {
        text-align: center;
      }
    }

    .content_header {
      padding: 25px 15px;
      background: #ffffff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      p {
        font-size: 0.9rem;
        font-weight: bold;
        color: #1f216e
      }
    }

    .table {
      thead {
        tr {
          th {
            font-size: 0.7rem !important;
            border: none;
            color: #1f216e;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 0.8rem;
            padding-top: 25px;
            padding-bottom: 25px;
            border: none;
            cursor: pointer;
            color: #1f216e;

            strong {
              color: #2d3f8e;
            }

            span {
              cursor: pointer;
              color: #2d3f8e;
            }

            a {
              i {
                color: #2d3f8e;
              }
            }
          }
        }
      }
    }

    .no_record {
      text-align: center;

      p {
        color: #2d3f8e;
        font-size: 0.8rem;
      }
    }
  }
}

.b-table {
  tbody {
    tr {
      &:focus {
        outline: none !important;
      }
    }
  }
}

.expand_card {
  //margin-top: -35px;
  //margin-bottom: -20px;
  //border-top-color: #f0f4f4;
  //border-left-color: #FFFFFF;
  //border-right-color: #FFFFFF;
  //border-bottom-color: #FFFFFF;

  margin-top: -25px;
  margin-bottom: -20px;
  border-color: #FFFFFF;
  border-radius: 10px;

  .modifier_heading {
    color: #1f216e !important;
  }

  .d-flex {
    .flex_item {
      padding-right: 1.5rem;
      padding-bottom: 1rem;
      width: 9rem;

      strong {
        color: #1f216e !important;
      }

      p {
        color: #1f216e !important;
      }
    }
  }
}

.cards {
  min-width: 90%;
}

.main_loader {
  left: 50%;
  position: absolute;
  top: 50%;
}

.width_65 {
  min-width: 750px;
  width: 750px;
}

.current_sb_width {
  width: 370px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  deleteAddonModifier,
  deleteSubscriptionURL,
  fetchBillingDetails, fetchComputedUserLimits,
  fetchPaymentHistoryURL, fetchSubscriptionDetailsURL,
  pauseSubscriptionURL, resumeSubscriptionURL
} from '../../../../../config/api'
import http from '@/mixins/http-lib'
import getSymbolFromCurrency from 'currency-symbol-map'
import IncreaseLimitsDialog from '@/views/pages/setting/billing/dialog/IncreaseLimitsDialog'
import CancelSubscription from '@/views/pages/setting/billing/dialog/CancelSubscription'
import { YEARLY_PLANS } from '@/common/constants'

export default {
  name: 'plan',
  props: ['subscription'],
  components: {
    IncreaseLimitsDialog,
    CancelSubscription
  },
  computed: {
    ...mapGetters(['getSubscription', 'getBillingLoaders', 'getAddonBaseSubscriptionList', 'getLimitConsumedError']),
    isShowNextPayment () {
      return (this.subscription_detail && this.subscription_detail.payment_details.subscription && this.subscription_detail.payment_details.subscription.next_payment_amount && !this.isLifeTimeUser)
    },
    isLifeTimeUser () {
      return (this.subscription_detail.subscription_name.toLowerCase()).includes(['lifetime'])
    },
    isAddonSubscriptionPaused () {
      return this.addon_subscriptions_items ? this.addon_subscriptions_items.some(item => item.STATUS === 'Paused') : false
    },

    // Computed property to determine the subscription duration based on the subscription name and additional conditions.
    subscriptionDuration () {
      const subscriptionName = this.subscription_detail.subscription_name

      if (YEARLY_PLANS.includes(subscriptionName)) {
        return 'Year'
      }
      if (this.isBruceCampaign()) {
        return 'Lifetime'
      }
      return 'Month'

    }
  },
  data () {
    return {
      brands: 40,
      subscription_detail: null,
      addon_subscriptions: [],
      loaders: {
        payment_details: true,
        addons: true,
        invoices: true,
        pause_subscription: false,
        resume_subscription: false,
        cancel_subscription: false,
      },
      addon_subscriptions_fields: ['DATE', 'AMOUNT', 'NEXT_PAYMENT_DATE', 'NEXT_PAYMENT', 'STATUS', 'ACTION'],
      addon_subscriptions_items: [],
      isShowAddonsModal: false,
      isShowModel: false,
      trialUser: true,
      addonConsumedLimitDetail: {},
      paymentHistoryPagination: {
        page: 1,
        limit: 10,
        total_pages: 0,
        total_items: 0,
        items: [],
      }
    }
  },
  async created () {
    this.getSiteDetails
    await this.fetchBillingDetails()
    await this.fetchAddon()
    await this.fetchUserLimits()
    await this.fetchPaymentHistory()
    this.trialUser = await this.isTrialUser(this.getSubscription).isTrial
  },
  methods: {
    ...mapActions([
      'setAddonConsumedLimitDetail',
      'fetchAddonSubscriptions',
      'setLimitConsumedError',
      'setEditModifierLimits'
    ]),
    /**
     * getting the payment information data
     * key can be 'last_four_digits'/'expiry_date'
     * @param key
     * @returns {Promise<*|string>}
     */
    computePaymentInformation (key = '') {
      try {
        const paymentInformation = this.subscription_detail && this.subscription_detail.payment_details && this.subscription_detail.payment_details.subscription && this.subscription_detail.payment_details.subscription.payment_information ? this.subscription_detail.payment_details.subscription.payment_information : {}
        return paymentInformation && paymentInformation[key] ? paymentInformation[key] : (key === 'expiry_date') ? '**/****' : '****'
      } catch (e) {
        console.log(e, this.subscription_detail)
        return '****'
      }
    },
    async paginatePastTransactions (page) {
      await this.fetchPaymentHistory({ page: page })
    },
    async fetchBillingDetails () {
      let date = {
        label: ' This Month',
        value: await this.getCurrentMonthDateRange()
      }
      return await http.post(fetchBillingDetails, { date }).then(res => {
        if (res.data.status) {
          this.subscription_detail = res.data.data
        }
        this.loaders.payment_details = false
        this.loaders.pause_subscription = false
        this.loaders.resume_subscription = false
        return true
      }).catch(err => {
        return err
      })
    },
    async fetchAddon () {
      this.getBillingLoaders.addons = true

      let subscription = await this.fetchAddonSubscriptions()

      this.addon_subscriptions_items = subscription.data.map(item => {
        return {
          DATE: this.fetchBillingDate(item.created_at),
          CHECKOUT_ID: '',
          NEXT_PAYMENT_DATE: this.fetchBillingDate(item.next_bill_date),
          AMOUNT: this.findSymbolFromCurrencyCode(item.currency) + ' ' + item.last_payment_amount,
          _showDetails: false,
          passThrough: '',
          NEXT_PAYMENT: this.findSymbolFromCurrencyCode(item.currency) + ' ' + item.next_payment_amount,
          STATUS: this.capitalizeFirstLetter(this.getSplittedText(item.paddle_status, '_', ' ')),
          subscription_id: item.paddle_subscription_id,
          modifiers: item.modifiers,
          currency: item.currency
        }
      })

      this.getBillingLoaders.addons = false
    },

    /**
     * fetching the user consumed limits
     * @returns {Promise<unknown>}
     */
    async fetchUserLimits () {
      let date = {
        label: ' This Month',
        value: this.getCurrentMonthDateRange()
      }

      return await http.post(fetchComputedUserLimits, { date }).then(async res => {
        if (res.data.status) {
          this.addonConsumedLimitDetail.computedData = res.data.data
        }
      })
    },
    async fetchPaymentHistory (paginate = null) {
      this.getBillingLoaders.invoices = true
      return await http.post(fetchPaymentHistoryURL, paginate).then(res => {
        if (res.data.status) {
          this.paymentHistoryPagination = {
            page: res.data.data.current_page,
            limit: res.data.data.per_page,
            total_pages: res.data.data.last_page,
            total_items: res.data.data.total,
            items: res.data.data.data,
          }
        }
        this.getBillingLoaders.invoices = false
      }).catch(err => {
        return err
      })
    },
    async pauseSubscription () {
      var self = this
      swal({
          title: 'Are you sure?',
          type: 'warning',
          text: 'By pausing the subscription, all of your links will be paused until you restart the subscription.',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, Pause it!',
          cancelButtonText: 'No, Cancel!',
          closeOnConfirm: true,
          customClass: 'sweetAlert_box',
          closeOnCancel: true,
          animation: false
        },
        function (isConfirm) {
          if (isConfirm) {
            self.getBillingLoaders.pause_subscription = true
            http.post(pauseSubscriptionURL, {
              'subscription_id': self.subscription_detail.payment_details.subscription_id
            }).then(async res => {
              if (res.data.status) {
                self.$store.dispatch('toastNotification', {
                  message: res.data.message,
                  type: 'success'
                })
                location.reload()
                return
              }
              self.$store.dispatch('toastNotification', {
                message: res.data.message,
                type: 'error'
              })
              self.getBillingLoaders.pause_subscription = false
            }).catch(err => {
              self.getBillingLoaders.pause_subscription = false
              return err
            })
          } else {
            swal()
          }
        })

    },
    async resumeSubscription () {
      this.getBillingLoaders.resume_subscription = true
      http.post(resumeSubscriptionURL, {
        'subscription_id': this.subscription_detail.payment_details.subscription_id
      }).then(async res => {
        if (res.data.status) {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'success'
          })
          location.reload()
          return
        }
        this.$store.dispatch('toastNotification', {
          message: res.data.message,
          type: 'error'
        })
        this.getBillingLoaders.resume_subscription = false

      }).catch(err => {
        this.getBillingLoaders.resume_subscription = false
        return err
      })
    },
    findSymbolFromCurrencyCode (code) {
      return getSymbolFromCurrency(code)
    },
    updatePayment (url) {
      let self = this
      Paddle.Checkout.open({
        override: url,
        successCallback: function (data) {
          this.$store.dispatch('toastNotification', {
            message: 'You billing details have been updated successfully.',
            type: 'success'
          })
          self.fetchBillingDetails()
        }
      })
    },
    async expandAdditionalInfo (row) {
      row._showDetails = !row._showDetails
    },
    async updateAddonDetails (row) {
      let sub_id = await row.item.subscription_id
      http.post(fetchSubscriptionDetailsURL, {
        'subscription_id': sub_id
      }).then(res => {
        if (res.data.status) {
          this.updatePayment(res.data.data.subscription.update_url)
        }
      }).catch(err => {
        return err
      })

    },
    /**
     * we are deleting here the base addon subscription on the basis of subscription_id
     * @param row
     * @returns {Promise<void>}
     */
    async deleteAddonDetails (row) {
      var sub_id = await row.item.subscription_id
      http.post(deleteSubscriptionURL, {
        'subscription_id': sub_id
      }).then(res => {
        if (res.data.status) {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'success'
          })
          location.reload()
        }
      }).catch(err => {
        return err
      })
    },
    async showUpgradePlanModal () {
       return !this.isBruceCampaign() ? await this.$bvModal.show('upgrade-subscription-modal') : await this.$bvModal.show('upgrade-ltd-subscription-modal')
    },
    isUpgradeModalTriggered () {
      if (this.isBruceCampaign()) {
        // this.$router.push({name: 'upgrade_lifetime_addon'})
        this.showUpgradeLimitsModal()
      } else {
        this.$bvModal.show('upgrade-subscription-modal')
      }
    },
    showUpgradeLimitsModal (modifer = {}) {
      this.isShowAddonsModal = true
      this.setEditModifierLimits(modifer)
      setTimeout(() => {
        this.$bvModal.show('increase-limits-dialog')
      }, 100)
    },
    closeModal () {
      this.isShowAddonsModal = false
      this.fetchBillingDetails()
      this.fetchAddonSubscriptions()
      this.fetchPaymentHistory()
    },
    showCancelSubscriptionModal (callType = '', item = {}) {
      this.setLimitConsumedError(false)

      if (callType === 'main_subscription') {
        this.setAddonConsumedLimitDetail({ callType: callType })
      }

      if (callType === 'addon_subscription') {
        this.setAddonConsumedLimitDetail({ callType: callType, passData: item })
      }

      if (callType === 'addon_modifier') {
        this.setAddonConsumedLimitDetail({ callType: callType, passData: item })
      }

      setTimeout(() => {
        this.$bvModal.show('cancel-subscription')
      }, 100)

    },
    /**
     * We are deleting the subscription modifier on the bases of modifier_id & paddle_subscription_id
     * @param item
     */
    deleteAddonModifier (item) {
      http.post(deleteAddonModifier, {
        'modifier_id': item.modifier_id,
        'subscription_id': item.paddle_subscription_id
      }).then(res => {
        if (res.data.status) {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'success'
          })
          location.reload()
        }
      }).catch(err => {
        return err
      })
    }
  }
}
</script>
