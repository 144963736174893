<template>
  <b-modal modal-class="normal_modal bv-modal-theme-default bv-modal-theme-upgrade-limits cancel_subscription"
           :id="modalId"
           hide-footer
           no-close-on-backdrop
           hide-header>
    <div class="modal_head d-flex align-items-center">
      <div class="modal-heading-with-text">
        <h2>{{ computeModalTitle }}</h2>
      </div>
      <b-button variant="studio-theme-grey-icon" class="mr-4" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </div>
    <div class="modal_body basic_form  " v-on-clickaway="close">

      <div v-if="isCallTypeAddon">
        <p class="text-center mb-2 font-weight-bold">Your addons subscription detail</p>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Addons</th>
            <th class="text-center">Additional Limits</th>
            <th class="text-center">Consumed Limits</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="getAddonConsumedLimitDetail.callType === 'addon_modifier'" :key="key"
              v-for="(item, key) in addonConsumedLimitDetail.computedData">
            <td class="capitalize__text">{{ getSplittedText(key, '_', ' ') }}</td>
            <td class="text-center">{{ getAddonConsumedLimitDetail.passData['addons'][key] }}</td>
            <td :class="{'text-danger': computeConsumedForModifierCase({key, ...item}) }" class="text-center">
              {{ computeConsumedForModifierCase({key, ...item}) }}
            </td>
          </tr>
          <tr v-else>
            <td class="capitalize__text">{{ getSplittedText(key, '_', ' ') }}</td>
            <td class="text-center">{{ item.total }}</td>
            <td :class="{'text-danger': isBaseAddonConsumed(item) }" class="text-center">
              {{ item.used }}
            </td>
          </tr>
          </tbody>
        </table>
        <div v-if="getLimitConsumedError" class="alert alert-warning" role="alert">
          <i class="fal fa-exclamation-triangle mr-2"></i> Remove consumed add-ons first to delete the add-on
          subscription.
        </div>
      </div>
      <div v-else>
        <p>
          We're sorry to see you go! Our support team will continue to cancel your subscription soon.
          We'd appreciate it if you would take a momnet to let us know why you are cancelling.
        </p>

        <div class="checkbox-list-block mt-3">
          <div class="checkbox_input_image">
            <input type="checkbox"
                   id="1"
                   v-model="states.understand"
                   value="I didn't understand how to use the product">
            <label for="1" class="checkbox_left" value="I didn't understand how to use the product">
              I didn't understand how to use the product
            </label>
          </div>

          <div class="checkbox_input_image">
            <input type="checkbox"
                   id="2"
                   v-model="states.missed"
                   value="I missed certain features">
            <label for="2" class="checkbox_left" value="I missed certain features">
              I missed certain features
            </label>
          </div>

          <div class="checkbox_input_image">
            <input type="checkbox"
                   id="3"
                   v-model="states.better"
                   value="I'm using a better product">
            <label for="3" class="checkbox_left" value="I'm using a better product">
              I'm using a better product
            </label>
          </div>

          <div class="checkbox_input_image">
            <input type="checkbox"
                   id="4"
                   v-model="states.organize"
                   value="I don't have time to organize everything from the beginning">
            <label for="4" class="checkbox_left" value="I don't have time to organize everything from the beginning">
              I don't have time to organize everything from the beginning
            </label>
          </div>

          <div class="checkbox_input_image">
            <input type="checkbox"
                   id="5"
                   v-model="states.need"
                   value="I don't have a need to use it at this moment">
            <label for="5" class="checkbox_left" value="I don't have a need to use it at this moment">
              I don't have a need to use it at this moment
            </label>
          </div>

          <div class="checkbox_input_image">
            <input type="checkbox"
                   id="6"
                   v-model="states.expensive"
                   value="It is expensive">
            <label for="6" class="checkbox_left" value="It is expensive">
              It is expensive
            </label>
          </div>

          <div class="checkbox_input_image" @click="commentBox()">
            <input type="checkbox"
                   id="7"
                   v-model="states.other"
                   value="Other">
            <label for="7" class="checkbox_left" value="Other">
              Other
            </label>
          </div>

          <div class="mt-2" v-if="states.other">
            <textarea name="" id="" cols="30" rows="5"
                      v-model="otherComment"
                      placeholder="Write your comment"></textarea>
          </div>
        </div>
      </div>


      <div class="modal_footer mt-2">
        <transition v-if="!isCallTypeAddon" name="fade">
          <ui-button
            :type="'add'"
            :color="'#ffffff'"
            :bgColor="'#3988fe'"
            :text="'Submit'"
            v-if="isShowSubmit"
            :disabled="loaders.submit"
            @click="submit()"
          />
        </transition>
        <transition v-else name="fade">
          <ui-button
            :type="'add'"
            :color="'#ffffff'"
            :bgColor="'#3988fe'"
            :showLoader="loaders.cancelSubscription"
            :loaderColor="'white'"
            :text="'Delete'"
            :disabled="getLimitConsumedError"
            @click="deleteSubscription()"
          />
        </transition>
      </div>
    </div>

  </b-modal>
</template>
<style lang="less">

.cancel_subscription {
  .modal-dialog {
    //max-width: 300px !important;
    max-width: 600px !important;
    width: 600px;
    min-width: 600px;
    margin-top: 68px;

    .modal-content {
      .modal_head {
        border-bottom: 1px solid fade(#989eb5, 5%);
        padding-left: 30px;

        h2 {
          display: inline-block;
          color: #3a4557;
          font-size: 1.125rem;
          font-weight: bold;
        }

        .close {
          outline: none !important;
          font-size: 2em;
          color: #3a4557;
          background: fade(#989eb5, 10%);
          text-shadow: none;
          font-weight: normal;
          opacity: 1;
          margin-left: auto;
        }

        .modal-heading-with-text {
          flex-grow: 1;
        }
      }

      .modal_body {
        padding: 20px 30px;

        .checkbox-list-block {
          .checkbox_input_image {
            display: block !important;
          }
        }
      }

      .modal_body.overlay {
        position: relative;

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: fade(#fff, 40%);

        }
      }

      .center_loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .modal_footer {
        button {
          float: right;
        }
      }
    }
  }
}

</style>
<script>

import {mapActions, mapGetters} from 'vuex'
import {cancelSubscriptionRequestURL} from '../../../../../config/api'
import http from '@/mixins/http-lib'

export default ({
  name: 'cancel-subscription',
  components: {},
  data() {
    return {
      modalId: 'cancel-subscription',
      otherComment: '',
      states: {
        understand: false,
        missed: false,
        better: false,
        organize: false,
        need: false,
        expensive: false,
        other: false
      },
      loaders: {
        submit: false,
        cancelSubscription: false
      }
    }
  },
  props: ['addonConsumedLimitDetail'],
  computed: {
    ...mapGetters(['getProfile', 'getSubscription', 'getAddonConsumedLimitDetail', 'getLimitConsumedError']),
    isShowSubmit() {
      if (this.states.understand || this.states.missed || this.states.better || this.states.organize || this.states.need || this.states.expensive) {
        return true
      } else if (this.states.other) {
        if (/^ *$/.test(this.otherComment)) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    computeModalTitle() {
      return this.getAddonConsumedLimitDetail.callType === 'main_subscription' ? 'Cancel Subscription' : (this.getAddonConsumedLimitDetail.callType === 'addon_subscription' ? 'Delete Addon Subscription' : 'Delete Modifier')
    },
    isCallTypeAddon() {
      return ['addon_subscription', 'addon_modifier'].includes(this.getAddonConsumedLimitDetail.callType)
    }
  },
  methods: {
    ...mapActions(['setLimitConsumedError']),
    close() {
      this.loaders.cancelSubscription = false

      this.states = {
        understand: false,
        missed: false,
        better: false,
        organize: false,
        need: false,
        expensive: false,
        other: false
      }

      this.otherComment = ''
      this.$bvModal.hide('cancel-subscription')
    },
    fetchMessageFromType(type) {
      if (type === 'understand') {
        return 'I didn\'t understand how to use the product'
      } else if (type === 'missed') {
        return 'I missed certain features'
      } else if (type === 'better') {
        return 'I\'m using a better product'
      } else if (type === 'organize') {
        return 'I don\'t have time to organize everything from the beginning'
      } else if (type === 'need') {
        return 'I don\'t have a need to use it at this moment'
      } else if (type === 'expensive') {
        return 'It is expensive'
      } else if (type === 'other') return 'Other'
    },

    computeConsumedForModifierCase(item) {
        const difference = this.getAddonConsumedLimitDetail.passData['addons'][item.key] - (item.total - item.used)
        if (difference > 0) {
          this.setLimitConsumedError(true)
        }
        return difference < 0 ? 0 : difference
    },
    isBaseAddonConsumed(item) {
      if(item.used) {
        this.setLimitConsumedError(true)
      }
      return !!item.used
    },
    submit() {

      if (this.states.other && !this.otherComment) {
        this.alertMessage(`Comment's minimum character count should be 100.`, 'error')
        return;
      }

      if (this.states.other && this.otherComment && this.otherComment.length <= 100) {
        this.alertMessage('Comment should not be less than 100 characters!', 'error')
        return
      }

      this.loaders.submit = true
      let comments = []
      if (this.states.understand) comments.push(this.fetchMessageFromType('understand'))
      if (this.states.missed) comments.push(this.fetchMessageFromType('missed'))
      if (this.states.better) comments.push(this.fetchMessageFromType('better'))
      if (this.states.organize) comments.push(this.fetchMessageFromType('organize'))
      if (this.states.need) comments.push(this.fetchMessageFromType('need'))
      if (this.states.expensive) comments.push(this.fetchMessageFromType('expensive'))
      if (this.states.other && this.otherComment !== '') comments.push(this.otherComment)

      http.post(cancelSubscriptionRequestURL, {
        'comments': comments
      }).then(res => {
        if (res.data.status) {
          this.loaders.submit = false
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'success'
          })
          location.reload()
          this.close()
        }
      }).catch(err => {
        this.close()
        this.loaders.submit = false
        return err
      })
    },
    /**
     * This method is responsible for to Delete Addon modifiers & addon base subscription
     */
    deleteSubscription() {
      this.loaders.cancelSubscription = true
      if (this.getAddonConsumedLimitDetail.callType === 'addon_subscription') {
        this.$parent.deleteAddonDetails(this.getAddonConsumedLimitDetail.passData)
      }

      if (this.getAddonConsumedLimitDetail.callType === 'addon_modifier') {
        this.$parent.deleteAddonModifier(this.getAddonConsumedLimitDetail.passData)
      }
    }
  }
})
</script>
