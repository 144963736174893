<template>
  <div class="setting_form clear">
    <!--<upgrade></upgrade>-->
    <increase-limits-dialog v-if="isShowAddonsModal"/>


    <div class="white_box integration-block ">
      <div class="box_content clear billing-col">
        <Plan/>
      </div>
    </div>

    <div class="white_box integration-block  " v-if="false">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left"><h2>Billing</h2></div>
          <div class="right ml-auto">
            <button @click="isUpgradeModalTriggered()"
                    class="btn---cta btn-blue with-shadow btn-round btn-bold">
              <span>Upgrade Plan</span>
              <span class="icon_right">
                           <i class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                        </span>
            </button>
          </div>
        </div>


        <div class="box_content clear billing-col">
          <div class=" billing-inner  d-flex align-items-center" v-if="getSubscription.fastspring_name">
            <div class="billing-left">
              <div class="left-inner">
                <p class="current-plan">Current Plan</p>
                <h3>{{ getSubscription.display_name }}</h3>
                <!--<p class="small-text">Unlimited access to the essential tools.</p>-->
                <!--<p class="desc-text">Next Payment: $49 on Jan 1, 2019</p>-->
                <!-- <button class="mt-3 btn-&#45;&#45;cta  light-blue btn-round">
                     <span>Cancel Subscription</span>
                 </button>-->
              </div>
            </div>
            <div class="billing-right">
              <div class=" list_plan">
                <ul>
                  <li>
                    <span>Clicks per month</span>
                    <span>{{ getSubscription.limits.clicks }}</span>
                  </li>
                  <li>
                    <span>Brands</span>
                    <span v-if="getSubscription.limits.brands > 1000">Unlimited</span>
                    <span v-else>{{ getSubscription.limits.brands }}</span>
                  </li>
                  <li>
                    <span>Campaigns</span>
                    <span v-if="getSubscription.limits.campaigns > 100">Unlimited</span>
                    <span v-else>{{ getSubscription.limits.campaigns }}</span>
                  </li>
                  <li>
                    <span>Retargeting Pixels</span>
                    <span v-if="getSubscription.limits.retargeting_pixels > 100">
                                          Unlimited
                                        </span>
                    <span v-else>
                      {{ getSubscription.limits.retargeting_pixels }}
                    </span>
                  </li>
                  <li>
                    <span>Custom Domains</span>
                    <span class="" v-if="getSubscription.limits.custom_domains > 0">
                                        <span v-if="getSubscription.limits.custom_domains >= 100">
                                                                    Unlimited
                                                              </span>
                                        <span v-else>
                                            {{ getSubscription.limits.custom_domains }}
                                            </span>
                                         </span>
                    <span v-else>

                                         <span class="round_icon not_allowed">
                                              <i class="fal fa-times "></i>
                                         </span>
                                    </span>
                  </li>
                  <li>
                    <span>Team members</span>
                    <span>
                                            {{ getSubscription.limits.team_members }}
                                        </span>
                  </li>
                  <li v-if="getSubscription.limits.bio_links">
                    <span>Bio Links</span>
                    <span>
                                            <template
                                              v-if="getSubscription.limits.bio_links > 1000">Unlimited</template>
                                            <template v-else>{{ getSubscription.limits.bio_links }}</template>
                                        </span>
                  </li>
                  <li>
                    <span>Branding</span>
                    <span v-if="getSubscription.limits.branding">
                      Yes
                    </span>
                    <span v-else>
                      No
                    </span>
                  </li>

                  <li v-if="getSubscription.limits.whitelabel">
                    <span>White-label</span>
                    <span>
                                            Coming Soon
                                        </span>
                  </li>
                  <li v-if="getSubscription.limits.deep_linking">
                    <span>Deep Linking</span>
                    <span>
                                            Coming Soon
                                        </span>
                  </li>

                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Upgrade from './Upgrade.vue'
import IncreaseLimitsDialog from '@/views/pages/setting/billing/dialog/IncreaseLimitsDialog'
import EventBus from '@/state/event-bus'
import Plan from './billing/components/Plan'
import { ROLES } from '@/common/constants'

export default ({
  components: {
    Upgrade,
    IncreaseLimitsDialog,
    Plan
  },
  data () {
    return {
      isAllowed: false,
      isShowAddonsModal: false
    }
  },
  created () {
    if (['waqar.azeem1986@gmail.com', 'muhammad.azhar109@gmail.com'].includes(this.getProfile.email)) {
      this.isAllowed = true
    }
    if (this.getProfile && this.getProfile.role && this.getProfile.role != ROLES.super_admin) {
      this.$router.push({ 'name': 'dashboard' })
    }

    if (!this.getSubscription.fastspring_name) {
      this.getUserSubscription()
    }

    const queryParams = this.$router.currentRoute.query;
    if(queryParams.modal && queryParams.modal === 'upgrade-subscription-modal'){
      this.$bvModal.show(queryParams.modal)
    }
  },
  computed: {
    ...mapGetters(['getSubscription', 'getProfile'])
  },
  mounted () {
    EventBus.$on('close_addon_modal', (payLoad) => {
      this.closeModal()
    })
  },
  methods: {
    isUpgradeModalTriggered () {
      if (this.isBruceCampaign() && this.isAllowed) {
        // this.$router.push({name: 'upgrade_lifetime_addon'})
        this.showModal()
      } else {
        this.$bvModal.show('upgrade-subscription-modal')
      }
    },
    showModal () {
      this.isShowAddonsModal = true
      setTimeout(() => {
        this.$bvModal.show('increase-limits-dialog')
      }, 100)
    },
    closeModal () {
      this.$bvModal.hide('increase-limits-dialog')
      this.isShowAddonsModal = false
    }
  }

})
</script>
<style lang="less">

</style>
