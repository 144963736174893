<template>
  <div class="upgrade-limits-section">
    <!--                <h2>Choose Limits</h2>-->
    <template v-if="!showCheckout">
      <transition name="fade">
        <table class="table table-increase-limits">
          <thead>
          <tr>
            <th width="50%"></th>
            <th>Quantity <i class="ml-2 far fa-question-circle c-pointer"
                            v-tooltip="'Select the quantity that you want to add into your existing account limits'"></i>
            </th>
            <th>Total Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Brands</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.brands.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{
                addon_price.monthly.brands.price *
                addon_price.monthly.brands.quantity
              }}/month
            </td>
          </tr>

          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Campaigns</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.campaigns.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{ parseCampaignPrice() }}/month
            </td>
          </tr>

          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Clicks</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.clicks.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{ parseClicksPrice() }}/month
            </td>
          </tr>


          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Retargeting Pixels</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.retargeting_pixels.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{
                addon_price.monthly.retargeting_pixels.price *
                addon_price.monthly.retargeting_pixels.quantity
              }}/month
            </td>
          </tr>


          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Custom Domains</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.custom_domains.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{
                addon_price.monthly.custom_domains.price *
                addon_price.monthly.custom_domains.quantity
              }}/month
            </td>
          </tr>


          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Team Members</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.team_members.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{
                addon_price.monthly.team_members.price *
                addon_price.monthly.team_members.quantity
              }}/month
            </td>
          </tr>


          <tr class="spacer"> &nbsp;</tr>
          <tr>
            <td class="font-weight-500">Bio-links</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0">
                  <input min="0"
                         v-model="addon_price.monthly.bio_links.quantity"
                         type="number">
                </div>
              </div>
            </td>
            <td class="text-blue">${{
                addon_price.monthly.bio_links.price *
                addon_price.monthly.bio_links.quantity
              }}/month
            </td>
          </tr>


          <tr  v-if="!subscription.limits.branding" class="spacer"> &nbsp;</tr>
          <tr v-if="!subscription.limits.branding">
            <td class="font-weight-500">Replug Branding</td>
            <td>
              <div class="basic_form">
                <div class="input_field m-0 no-bg text-center">
                  <label class="switch">
                    <input class="switch_replug" type="checkbox" v-model="addon_price.monthly.branding.value"
                           @change="updateBrandingPrice()">
                    <div class="slider round"></div>
                  </label>
                </div>
              </div>
            </td>
            <td class="text-blue" v-if="!subscription.limits.branding && addon_price.monthly.branding.value">${{
                addon_price.monthly.branding.price
              }}/month
            </td>
            <td class="text-blue" v-else>$0/month
            </td>
          </tr>

          <tr class="spacer"> &nbsp;</tr>

          <tr class="last-row">
            <td>
              <div class="flex-column">
                <div class="bill-type">
                  Bill Type
                </div>
                <div class="bill-type-selection">
                  <b-form-radio :disabled="disableBillType('annually')" button-variant="outline-primary"
                                :class="{'active': bill_type === 'monthly'}"
                                v-model="bill_type" name="some-radios" value="monthly">Monthly
                  </b-form-radio>
                  <b-form-radio :disabled="disableBillType('monthly')"
                    :class="{'active': bill_type === 'annually'}"
                    button-variant="outline-primary" v-model="bill_type" name="some-radios"
                    value="annually">Annually
                  </b-form-radio>
                </div>

              </div>
            </td>
            <td class="grand-total">
              Grand Total
            </td>
            <td>
              <div class="bill-amount-section">
                <div class="bill-amount-text">
                  <template v-if="bill_type === 'monthly'">
                    Billed Monthly
                  </template>
                  <template v-else>
                    Billed Annually
                  </template>

                </div>
                <div class="bill-amount-price">
                  ${{ getAddonsPrice }}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </transition>
      <transition name="fade">
        <div class="increase-limits-call-to-action">
          <div class="left-section-increase-limits">
            <b-alert variant="studio-theme-warning" show
                     v-if="bill_type === 'monthly' && getAddonsPrice < 10"><i
              class="far fa-exclamation-circle"></i>
              Monthly billing is only available for orders greater than <span class="font-weight-500 display_content"> $10/month.</span>

            </b-alert>
          </div>
          <div class="right-section-increase-limits">
            <ui-button
              :type="'add'"
              :color="'#3a4557'"
              :bgColor="'rgba(69, 162, 255, 0.1)'"
              :text="'Cancel'"
              @click="close()"
              class="mr-2"
            />
            <ui-button
              :type="'add'"
              :color="'#ffffff'"
              :showLoader="purchaseLoader"
              :loaderColor="'white'"
              :bgColor="'#3988fe'"
              :text="getEditModifierLimits && getEditModifierLimits._id ? 'Update Modifier' : 'Purchase'"
              v-on:click="purchaseAddonAction()"
              :disabled="(bill_type === 'monthly' && getAddonsPrice < 10) || isIncreasingLimitsLoader || getAddonsPrice <= 0"
            />
            <!--        <b-button variant="studio-theme-space"-->
            <!--                  @click="purchaseAddonAction()"-->
            <!--                  :disabled="(bill_type === 'monthly' && getAddonsPrice < 10) || isIncreasingLimitsLoader">-->
            <!--          <template v-if="isIncreasingLimitsLoader">-->
            <!--            Increasing limits, please wait-->
            <!--            <clip-loader class="ml-2" :size="'12px'" :color="'#fff'"></clip-loader>-->
            <!--          </template>-->
            <!--          <template v-else>-->
            <!--            Purchase-->
            <!--          </template>-->

            <!--        </b-button>-->
          </div>
        </div>
      </transition>
    </template>

    <!--    <template v-else>-->
    <a v-if="showCheckout" @click="showCheckout = !showCheckout" style="cursor: pointer" class="back_button">
      <i class="fal fa-chevron-left"></i>Back
    </a>
    <!--    <transition name="fade">-->
    <clip-loader v-if="showCheckout && loaders.increase_limits" :color="'#0095f3'" :size="'20px'"></clip-loader>
    <div v-if="showCheckout" class="checkout-container" id="checkout-container">
    </div>
    <!--    </transition>-->
    <!--    </template>-->

  </div>
</template>

<style lang="less">
@import (reference) "../../../../../assets/less/colors";

.upgrade-limits-section {
  padding: 1rem 1.5rem;
  border-radius: 15px;
  background: @white;

  h2 {
    padding: 1rem 0;
    font-size: 1.3em;
    font-weight: 500;
  }

  .table-increase-limits {
    margin: 1rem 0;

    thead {
      tr {
        th {
          font-size: 1.15em;
          padding: 1rem 1rem;
          border-bottom: 0;
          border-top: 0;
          background: rgba(58, 69, 87, 0.04) !important;

          &:first-child {
            border-top-left-radius: 0.625rem;
            border-bottom-left-radius: 0.625rem;
          }

          &:last-child {
            border-top-right-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
          }

          i {
            font-size: 1em;
            color: @cs-blue-text-color;
          }
        }

      }
    }

    tbody {
      tr {
        border-top: 0;

        td {
          vertical-align: middle;
          font-size: 1.15em;
          background: rgba(58, 69, 87, 0.04) !important;
          padding: 1rem 1rem;
          border-top: 0;

          &:first-child {
            border-top-left-radius: 0.625rem;
            border-bottom-left-radius: 0.625rem;
          }

          &:last-child {
            border-top-right-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
          }

          .input_field {
            background: @white;
            max-width: 70%;
            font-weight: 500 !important;

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {

              opacity: 1;

            }
          }
        }

        .text-blue {
          font-weight: 500;
          color: @cs-dark-blue-text-color
        }
      }

    }

    .last-row {
      td {
        padding: 1rem 1rem;
        background: rgba(47, 138, 224, 0.1) !important;
      }

      .bill-type {
        font-weight: 500;
        padding: 0.5rem 0;
      }

      .grand-total {
        font-weight: 500;
        font-size: 1.4em;
        text-align: center;
      }

      .bill-type-selection {
        margin: 0.5rem 0;
        display: flex;
        flex-direction: row;

      }

      .bill-amount-section {
        display: flex;
        flex-direction: column;

        .bill-amount-text {
          font-size: 0.95em;
          color: rbga(@cs-text-color, 0.6);
        }

        .bill-amount-price {
          color: @cs-blue-text-color;
          font-size: 1.4em;
          font-weight: 500;
        }
      }
    }
  }

  .increase-limits-call-to-action {
    display: flex;
    align-items: center;

    .left-section-increase-limits {
      flex-grow: 1;

      .alert {
        width: 80%;
      }
    }

    .right-section-increase-limits {
      button {
        padding: 0.60rem 1.25rem;
      }
    }
  }
}

.normal_modal .modal-dialog .modal-content {
  background: #f4f7fa !important;
  border-radius: 20px;

  .modal_head {
    border-bottom: none !important;

    h2 {
      display: inline-block;
      color: #3a4557;
      font-weight: 500 !important;
      flex-grow: 1;
      justify-content: center;
      text-align: center;
      font-size: 1.6em !important;
      padding: 1rem 0;
      font-family: Roboto, sans-serif;
    }
  }
}

.custom-radio.active {
  color: #3a4557;
  background: rgba(47, 138, 224, .15);
}

.custom-radio {
  margin-right: 1rem;
  padding-top: .3rem;
  padding-bottom: .3rem;
  padding-left: 2rem;
  padding-right: 1rem;
  border-radius: 15px;
  color: rbga(#3a4557, .8);
}

.alert-studio-theme-warning {
  background: #faf2dc;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  border-radius: .625rem;
  color: #3a4557;
  font-weight: 400;
  padding: 1rem 1.25rem;
  font-size: 1em;
  margin-bottom: 0;

  i {
    font-weight: 500;
    font-size: 1.25em;
    margin-right: .5rem;
  }
}

.no-bg {
  background: transparent !important;
}

.checkout-container {
  height: 100%;
  margin: 0 auto;
  width: 55%;
}

.back_button {
  i {
    margin-right: 5px;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'
import {purchaseAddonModifier, getOverridePriceURL, deleteAddonModifier} from '@/config/api'
import EventBus from '@/state/event-bus';
import {paddle_ids} from "@/mixins/pricing_constants";

export default ({
  name: 'individual-limits-component',
  props: [
    'profile',
    'subscription'
  ],
  data () {
    return {
      paddle_ids: paddle_ids,
      purchaseLoader: false,
      showCheckout: false,
      passthrough: '',
      loaders: {
        increase_limits: false,
        paddle: true
      },
      modalId: 'increase-limits-dialog',
      bill_type: 'annually',
      addon_price: {
        monthly: {
          brands: {
            quantity: 1,
            price: 1,
            prev: 1
          },
          campaigns: {
            quantity: 3,
            min: 3,
            price: 1,
            prev: 3
          },
          retargeting_pixels: {
            quantity: 1,
            price: 1,
            prev: 1
          },
          custom_domains: {
            quantity: 1,
            price: 2,
            prev: 1
          },
          team_members: {
            quantity: 1,
            price: 2,
            prev: 1
          },
          bio_links: {
            quantity: 1,
            price: 1,
            prev: 1
          },
          clicks: {
            quantity: 3000,
            min: 3000,
            price: 1,
            prev: 3000
          },
          branding: {
            value: 'yes',
            price: 5,
            base_price: 5
          }
        }
      }
    }
  },
  created() {
    if(this.isActiveAddonSubscription) {
      this.bill_type = this.getActiveAddonDetail.bill_type
    }

    if(this.getEditModifierLimits) {
      this.addon_price = {
        monthly: {
          brands: {
            quantity: this.getEditModifierLimits.addons['brands'],
            price: 1,
            prev: this.getEditModifierLimits.addons['brands']
          },
          campaigns: {
            quantity: this.getEditModifierLimits.addons['campaigns'],
            min: 3,
            price: 1,
            prev: this.getEditModifierLimits.addons['campaigns']
          },
          retargeting_pixels: {
            quantity: this.getEditModifierLimits.addons['retargeting_pixels'],
            price: 1,
            prev: this.getEditModifierLimits.addons['retargeting_pixels']
          },
          custom_domains: {
            quantity: this.getEditModifierLimits.addons['custom_domains'],
            price: 2,
            prev: this.getEditModifierLimits.addons['custom_domains']
          },
          team_members: {
            quantity: this.getEditModifierLimits.addons['team_members'],
            price: 2,
            prev: this.getEditModifierLimits.addons['team_members'],
          },
          bio_links: {
            quantity: this.getEditModifierLimits.addons['bio_links'],
            price: 1,
            prev: this.getEditModifierLimits.addons['bio_links']
          },
          clicks: {
            quantity: this.getEditModifierLimits.addons['clicks'],
            min: 3000,
            price: 1,
            prev: this.getEditModifierLimits.addons['clicks']
          },
          branding: {
            value: 'yes',
            price: 5,
            base_price: 5
          }
        }
      }
    }

  },
  computed: {
    ...mapGetters(['getProfile', 'getActiveAddonDetail', 'getEditModifierLimits']),
    getAddonsPrice () {
      let sumAmount =
        (this.addon_price.monthly.brands.price * this.addon_price.monthly.brands.quantity) +
        (this.parseCampaignPrice()) +
        (this.parseClicksPrice()) +
        (this.addon_price.monthly.retargeting_pixels.price * this.addon_price.monthly.retargeting_pixels.quantity) +
        (this.addon_price.monthly.custom_domains.price * this.addon_price.monthly.custom_domains.quantity) +
        (this.addon_price.monthly.team_members.price * this.addon_price.monthly.team_members.quantity) +
        (this.addon_price.monthly.bio_links.price * this.addon_price.monthly.bio_links.quantity)
      if (!this.subscription.limits.branding) sumAmount += this.addon_price.monthly.branding.price
      if (this.bill_type === 'annually') return sumAmount * 12

      return sumAmount
    },
    isIncreasingLimitsLoader () {
      return this.loaders.increase_limits
    },
    isActiveAddonSubscription() {
      return this.getActiveAddonDetail && this.getActiveAddonDetail.paddle_subscription_id
    }
  },
  methods: {
    /**
     * check if paddle addon subscription bill type monthly or yearly then we will disable the bill type accordingly
     * @param billType
     * @returns {boolean}
     */
    disableBillType(billType = '') {
      if(!this.isActiveAddonSubscription) {
        return  false
      }
      return this.getActiveAddonDetail.bill_type === billType
    },
    /**
     * Getting the Addons plan id on the bases of ENV
     * @param type
     * @returns {*}
     */
    getAddonPaddlePlanId(type = '') {
      return paddle_ids[process.env.VUE_APP_ENVIRONMENT][type]
    },
    purchaseAddonAction () {
      let self = this

      if(this.purchaseLoader) {
        return
      }

      this.purchaseLoader = true

      // If in case we will delete first modifier and create new modifier
      if(this.getEditModifierLimits && this.getEditModifierLimits._id) {
        this.deleteAddonModifier(this.getEditModifierLimits)
      }

      // if Addons are already purchased so we directly buy the new addon without opening paddle popup
      this.showCheckout = !this.isActiveAddonSubscription

      setTimeout(() => {
        let productId = (this.bill_type === 'monthly') ? this.getAddonPaddlePlanId('replug_addons_monthly') : this.getAddonPaddlePlanId('replug_addons_annually')
        let branding = false

        if (this.addon_price.monthly.branding.value === 'yes') branding = true
        this.passthrough = {
          brands: parseInt(this.addon_price.monthly.brands.quantity),
          campaigns: parseInt(this.addon_price.monthly.campaigns.quantity),
          clicks: parseInt(this.addon_price.monthly.clicks.quantity),
          retargeting_pixels: parseInt(this.addon_price.monthly.retargeting_pixels.quantity),
          custom_domains: parseInt(this.addon_price.monthly.custom_domains.quantity),
          team_members: parseInt(this.addon_price.monthly.team_members.quantity),
          bio_links: parseInt(this.addon_price.monthly.bio_links.quantity),
          branding: branding,
          bill_type: this.isActiveAddonSubscription ? this.getActiveAddonDetail.bill_type : this.bill_type
        }

        // if user has purchased the addons then we will add new addon as modifier
        if(this.isActiveAddonSubscription) {
          this.purchaseLoader = true
          http.post(purchaseAddonModifier, {
            modifier_recurring: true,
            subscription_id: this.getActiveAddonDetail.paddle_subscription_id,
            modifier_amount: this.getAddonsPrice,
            modifier_description: JSON.stringify(this.passthrough)
          }).then(
            res => {
              this.purchaseLoader = false
              this.$store.dispatch('toastNotification', {
                message: res.data.message,
                type: 'success'
              })
              location.reload()
            }
          )
          return
        }

        http.post(getOverridePriceURL, {
          paddle_id: productId,
          price: this.getAddonsPrice
        }).then(
          resp => {

            var paddleOptions = {
              method: 'inline',
              product: productId,
              email: this.profile.email,
              title: '',

              price: this.getAddonsPrice,
              passthrough: this.passthrough,
              allowQuantity: false,
              disableLogout: true,
              frameTarget: 'checkout-container', // The className of your checkout <div>
              frameInitialHeight: 416,
              frameStyle: 'width:100%; min-width:495px; background-color: transparent; border: none;',    // Please ensure the minimum width is kept at or above 495px
              successCallback: function (data) {
                self.upgradeCallback(data)
              }
            }

            paddleOptions.auth = resp.data.token
            // check if add on is monthly then set the recurring price
            // also passing the data payload.
            paddleOptions.recurringPrices = [
              {
                currency: 'USD',
                price: this.getAddonsPrice,
                auth: resp.data.token,
                quantity: 1
              }
            ]

            Paddle.Checkout.open(paddleOptions)
          }
        ).catch()
      }, 500)

    },
    upgradeCallback (data) {
      location.reload()
      this.loaders.increase_limits = true
      this.$emit('update', data)
    },
    parseCampaignPrice () {
      const price = (this.addon_price.monthly.campaigns.quantity / this.addon_price.monthly.campaigns.min) *
        this.addon_price.monthly.campaigns.price
      if (!Number.isInteger(price)) {
        return Number(Math.round(price + 'e' + 2) + 'e-' + 2)
      }
      return price
    },
    parseClicksPrice () {
      const price = (this.addon_price.monthly.clicks.quantity / this.addon_price.monthly.clicks.min) *
        this.addon_price.monthly.clicks.price
      if (!Number.isInteger(price)) {
        return Number(Math.round(price + 'e' + 2) + 'e-' + 2)
      }
      return price
    },
    updateBrandingPrice () {
      if (this.subscription.limits.branding) {
        this.addon_price.monthly.branding.price = 0
        this.addon_price.monthly.branding.value = 'no'
      }
      else if (this.addon_price.monthly.branding.value) {
        this.addon_price.monthly.branding.price = parseInt(this.addon_price.monthly.branding.base_price)
      } else {
        this.addon_price.monthly.branding.price = 0
      }
    },
    isInteger( str ) {
      const regex = new RegExp(/^[0-9]{1,8}$/);
      return regex.test( str );
    },
    close () {
      EventBus.$emit('close_addon_modal', true);
    },
    /**
     * We are deleting the subscription modifier on the bases of modifier_id & paddle_subscription_id
     * @param item
     */
    deleteAddonModifier(item) {
      http.post(deleteAddonModifier, {
        'modifier_id': item.modifier_id,
        'subscription_id': item.paddle_subscription_id
      }).then(res => {
        if (res.data.status) {}
      }).catch(err => {
        return err
      })
    }
  },
  watch: {
    'addon_price.monthly.brands.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.brands.quantity = this.addon_price.monthly.brands.prev
      }

      if (value < 0) {
        this.addon_price.monthly.brands.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.brands.quantity = 1000
      }
      this.addon_price.monthly.brands.prev = this.addon_price.monthly.brands.quantity

    },
    'addon_price.monthly.campaigns.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.campaigns.quantity = this.addon_price.monthly.campaigns.prev
        return
      }
      if (value < 0) {
        this.addon_price.monthly.campaigns.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.campaigns.quantity = 1000
      } else {
        if (value > this.addon_price.monthly.campaigns.prev) {
          this.addon_price.monthly.campaigns.quantity = Math.ceil((value / this.addon_price.monthly.campaigns.min)) * this.addon_price.monthly.campaigns.min
        } else {
          this.addon_price.monthly.campaigns.quantity = Math.floor((value / this.addon_price.monthly.campaigns.min)) * this.addon_price.monthly.campaigns.min
        }
        this.addon_price.monthly.campaigns.prev = this.addon_price.monthly.campaigns.quantity
      }
    },
    'addon_price.monthly.retargeting_pixels.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.retargeting_pixels.quantity = this.addon_price.monthly.retargeting_pixels.prev
      }
      if (value < 0) {
        this.addon_price.monthly.retargeting_pixels.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.retargeting_pixels.quantity = 1000
      }
      this.addon_price.monthly.retargeting_pixels.prev = this.addon_price.monthly.retargeting_pixels.quantity

    },
    'addon_price.monthly.custom_domains.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.custom_domains.quantity = this.addon_price.monthly.custom_domains.prev
      }
      if (value < 0) {
        this.addon_price.monthly.custom_domains.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.custom_domains.quantity = 1000
      }
      this.addon_price.monthly.custom_domains.prev = this.addon_price.monthly.custom_domains.quantity

    },
    'addon_price.monthly.team_members.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.team_members.quantity = this.addon_price.monthly.team_members.prev
      }
      if (value < 0) {
        this.addon_price.monthly.team_members.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.team_members.quantity = 1000
      }
      this.addon_price.monthly.team_members.prev = this.addon_price.monthly.team_members.quantity

    },
    'addon_price.monthly.bio_links.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.bio_links.quantity = this.addon_price.monthly.bio_links.prev
      }
      if (value < 0) {
        this.addon_price.monthly.bio_links.quantity = 0
      } else if (value > 1000) {
        this.addon_price.monthly.bio_links.quantity = 1000
      }
      this.addon_price.monthly.bio_links.prev = this.addon_price.monthly.bio_links.quantity
    },
    'addon_price.monthly.clicks.quantity' (value) {
      if(!this.isInteger(value)){
        this.addon_price.monthly.clicks.quantity = this.addon_price.monthly.clicks.prev
        return
      }
      if (value < 0) {
        this.addon_price.monthly.clicks.quantity = 0
        this.addon_price.monthly.clicks.prev = this.addon_price.monthly.clicks.quantity
      } else if (value > 10000000) {
        this.addon_price.monthly.clicks.quantity = 10000000
        this.addon_price.monthly.clicks.prev = this.addon_price.monthly.clicks.quantity
      } else {
        if (value > this.addon_price.monthly.clicks.prev) {
          this.addon_price.monthly.clicks.quantity = Math.ceil((value / this.addon_price.monthly.clicks.min)) * this.addon_price.monthly.clicks.min
        } else {
          this.addon_price.monthly.clicks.quantity = Math.floor((value / this.addon_price.monthly.clicks.min)) * this.addon_price.monthly.clicks.min
        }
        this.addon_price.monthly.clicks.prev = this.addon_price.monthly.clicks.quantity
      }
    }

  }
})
</script>
