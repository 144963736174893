<template>
  <b-modal modal-class="normal_modal bv-modal-theme-default bv-modal-theme-upgrade-limits" :id="modalId"
           hide-footer
           hide-header>
    <div class="modal_head d-flex align-items-center">
      <div class="modal-heading-with-text">
        <h2>{{ getEditModifierLimits && getEditModifierLimits._id ? 'Edit Modifier Limits' : 'Upgrade Limits' }}</h2>
        <!-- <p>
             Please note that this is a limited time offer so make full use of this offer looking at your future
             growth and needs.
         </p>-->
      </div>
      <b-button variant="studio-theme-grey-icon" class="mr-4" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </div>
    <div class="modal_body basic_form  " v-on-clickaway="close">
      <clip-loader :color="'#168eea'" v-if="loader"
                   :size="'#14px'"></clip-loader>
      <increase-limits-component v-else :profile="getProfile" :subscription="getSubscription" @update="upgradeAddonsCallback($event)"/>
    </div>

  </b-modal>
</template>
<style lang="less">
@media only screen and (max-width: 1600px) {
  .bv-modal-theme-upgrade-limits{
    font-size: 13px;
  }
  .table-increase-limits{
    thead{
      tr{
        td{
          padding:0.60rem 1rem !important;
        }
      }
    }
    tbody{
      tr{
        td{
          padding:0.60rem 1rem !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .bv-modal-theme-upgrade-limits{
    font-size: 12px;
  }

}

.modal.normal_modal, .modal-router.normal_modal{
  .modal-dialog{
    max-width: 600px;
    margin-top: 68px;
    .modal-content{
      .modal_head{
        border-bottom: 1px solid fade(#989eb5,5%);
        padding-left: 30px;
        h2{
          display: inline-block;
          color: #3a4557;
          font-size: 1.125rem;
          font-weight: bold;
        }
        .close{
          outline: none !important;
          font-size: 2em;
          color: #3a4557;
          background: fade(#989eb5,10%);
          text-shadow: none;
          font-weight: normal;
          opacity: 1;
          margin-left: auto;
        }
        .modal-heading-with-text {
          flex-grow: 1;
        }
      }
      .modal_body{
        padding: 20px 30px;
      }
      .modal_body.overlay{
        position: relative;
        &:after{
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: fade(#fff,40%);

        }
      }
      .center_loader{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
      }
    }
  }
}

</style>
<script>

import IncreaseLimitsComponent from './IncreaseLimitsComponent'
import { mapGetters } from 'vuex'

export default ({
  name: 'increase-limits-dialog',
  components: {
    IncreaseLimitsComponent
  },
  data () {
    return {
      modalId: 'increase-limits-dialog',
      loader: true
    }
  },
  created () {
    setTimeout(() => {
      this.loader = false
    },500)
  },
  computed: {
    ...mapGetters(['getProfile', 'getSubscription', 'getEditModifierLimits', 'self']),
  },
  methods: {
    upgradeAddonsCallback (data) {
      // this.loaders.increase_limits = true
      this.$store.dispatch('toastNotification', { message: 'Your purchase has been successfully processed. Please wait for few seconds while your limits are upgraded.' })
      setTimeout(async () => {
        await this.$store.dispatch('fetchProfile')
        this.getUserSubscription()
        // await this.$store.dispatch('fetchPlan')
        // this.loaders.increase_limits = false
        this.$bvModal.hide(this.modalId)
      }, 5000)
    },
    close () {
      this.$parent.closeModal()
      this.$bvModal.hide('increase-limits-dialog')
    }
  }
})
</script>
